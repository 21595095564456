var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loader || _vm.loaders.category),expression:"loader || loaders.category"}],ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"how-to-use-app-form mt-1"},[_c('base-input',{attrs:{"name":"title","label":"Title*","rules":{ custom_required: true },"placeholder":"Name"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('base-select',{attrs:{"name":"category","label":"Category","options":_vm.categoryOptions,"rules":{ custom_required: false }},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}}),_c('base-select',{attrs:{"name":"status","label":"Status*","options":_vm.statusOptions,"rules":{ custom_required: true }},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('validation-provider',{attrs:{"rules":{ custom_required: true, custom_url : true },"name":"link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Link*")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.link),expression:"form.link"}],staticClass:"form-control",class:[{ 'is-invalid': invalid && validated }],attrs:{"rows":"3"},domProps:{"value":(_vm.form.link)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "link", $event.target.value)}}}),(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"text-right mb-2"},[_c('base-button',{staticClass:"px-2 mr-3",attrs:{"type":"gray","size":"xl","disabled":_vm.loader},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")]),_c('base-button',{attrs:{"native-type":"submit","type":"dark-blue","size":"xl","disabled":_vm.loader}},[_vm._v(_vm._s(_vm.submitButton))])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }