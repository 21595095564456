<template>
    <el-dialog :visible.sync="visible" title="View Info" width="500px" :before-close="handleClose" class="how-to-use-app-show">
        <div class="border-top" v-if="howToUseApp">
            <div class="mt-4 d-flex align-items-end justify-content-between">
                <p class="custom-status" :class="howToUseApp.status ? 'active' : 'inactive'">{{ howToUseApp.status ? 'Active' : 'Inactive' }}</p>
                <base-button type="dark-blue" class="py-1" outline size="xl" @click="$emit('edit',howToUseApp)">Edit</base-button>
            </div>
            <div class="__details mt-3">
                <h3 class="__heading">{{ howToUseApp.name }}</h3>
                <p  class="__category">{{ howToUseApp.category ? howToUseApp.category.name: 'N/A' }}</p>
                <a  target="_blank" :href="howToUseApp.content" class="__link">{{ howToUseApp.content }}</a>
            </div>
        </div>
    </el-dialog>
</template>
  
<script>
export default {
    props: ['visible', 'howToUseApp'],
    components: {
    },
    data() {
        return {
            loader: false,
        };
    },
    mounted() {

    },
    watch: {

    },
    computed: {

    },
    methods: {
        handleClose() {
            this.$emit('closeDialog')
        },
    },
};
</script>
  
<style scoped lang="scss">
.how-to-use-app-show{
    .__details{
        .__heading{

        }
        .__category{
            margin: 0;
            font-size: 12px;
            font-weight: normal;

        }
        .__link{
            margin: 0;
            font-size: 12px;
            font-weight: normal;
        }
    }
}
</style>
  