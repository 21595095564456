<template>
    <el-dialog v-loading="loader" :title="title" :visible.sync="visible" width="500px" :before-close="handleClose">
        <how-to-use-app-form ref="form" :how-to-use-app="howToUseApp" @update="update" @add="add" @cancel="handleClose"/>
    </el-dialog>
</template>
  
<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
import HowToUseAppForm from "@/views/Components/SuperAdmin/HowToUseApp/Form";
export default {
    props: ['visible', 'howToUseApp'],
    mixins:[generateErrorMessageMixin],
    components:{
        HowToUseAppForm
    },
    data() {
        return {
            loader: false,
        };
    },
    mounted(){
        
    },
    watch: {
        
    },
    computed:{
        title(){
            return this.howToUseApp ? 'Edit' : 'Add New';
        }
    },
    methods: {
        handleClose() {
            let validation = this.$refs.form.$refs.formValidator;
            validation.reset();
            this.$refs.form.setValues({});
            this.$emit('closeDialog')
        },
        async update({fd,id}) {
            let validation = this.$refs.form.$refs.formValidator;
            this.loader = true;
            try {
                let response = await this.$store.dispatch('HowToUseAppModule/_updateHowToUseApp',{fd,id});
                let {  data : {data,message } } = response;
                this.$notify.success({
                    title: 'How To Use App',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$emit('updated');
                this.handleClose();
            } catch (error) {
                this.showErrorOnForm(error,'How To Use App',validation)

            }
            this.loader = false;
        },
        async add(fd) {
            let validation = this.$refs.form.$refs.formValidator;
            this.loader = true;
            try {
                let response = await this.$store.dispatch('HowToUseAppModule/_storeHowToUseApp',{fd});
                let {  data : {data,message } } = response;
                this.$notify.success({
                    title: 'How To Use App',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$emit('added');
                this.handleClose();
            } catch (error) {
                this.showErrorOnForm(error,'How To Use App',validation)

            }
            this.loader = false;
        }
    },
};
</script>
  
<style scoped lang="scss"></style>
  