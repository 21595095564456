<template>
    <div class="m-0 mb-5 px-3 pt-4 pb-0 pr-2 how-to-use-app-index">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <h1>{{ `How To Use App` }}</h1>
            <div>
                <base-button type="white" size="sm" class="px-4"
                    @click="$router.push({ name: 'admin.how-to-use-app.categories' })">Category</base-button>
                <base-button type="dark-blue" size="sm" class="px-4" @click="showAddEditModal">Add New</base-button>
            </div>
        </div>
        <div class="mt-4 cardStyle p-3">
            <div class="d-flex flex-wrap align-items-center filters" v-if="howToUseAppSelected.length == 0">
                <base-input label="Search" type="search" v-model="search.search" @input="filterSearch" placeholder="Search"
                    class="filter-search mr-2"></base-input>
                <base-select label="Status" v-model="search.status" @change="filterSearch" :options="activeOptions"
                    custom-clearable class="filter-search mr-2"></base-select>
            </div>
            <div class="d-flex flex-wrap py-4" v-else>
                <base-button type="danger" outline size="xl" @click="showMultipleDelete"
                    :disabled="loaders.multiple">Delete</base-button>
                <base-button type="active-green" outline size="xl" @click="toggleStatusMultiple(true)"
                    :disabled="loaders.multiple">Activate</base-button>
                <base-button type="spruce-blue" outline size="xl" @click="toggleStatusMultiple(false)"
                    :disabled="loaders.multiple">Deactivate</base-button>
            </div>
            <el-table-draggable handle=".handle" @drop="updateSortList">
                <el-table class="mt-4 mb-0 table-responsive table-flush" header-row-class-name="thead-light" width="100%"
                    :data="howToUseApp" v-loading="loaders.fetching" @selection-change="handleSelectionChange">
                    <el-table-column align="left" width="50">
                        <template slot-scope="scope" v-if="!(search.search || search.status.toString())">
                            <div class="handle btnDraggable" v-if="scope.row.id !== ''">
                                <img src="/img/icons/buttons/handle.svg" alt="+">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column type="selection" width="50">
                    </el-table-column>
                    <el-table-column label="TITLE" prop="name" width="550">
                        <template slot-scope="scope">
                            <div class="text-break">
                                {{ scope.row.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="CATEGORY" prop="category.name">
                        <template slot-scope="scope">
                            <div class="trim-text-overflow">
                                {{ scope.row.category ? scope.row.category.name : 'N/A' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="STATUS" prop="status" width="250" header-align="left" align="left">
                        <template slot-scope="scope">
                            <i class="fa fa-circle mr-2 "
                                :class="{ 'text-active-green': scope.row.status, 'text-silver': !scope.row.status }"
                                style="font-size: 7px;"></i>
                            <p class="text-break m-0 status d-inline-block" style="font-size: inherit;">{{ scope.row.status
                                ? "Active" :
                                "Inactive"
                            }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="ACTIONS" header-align="left" width="200">
                        <div slot-scope="{ $index, row }" class="d-flex">
                            <img class="eye-icon mr-2" src="/img/eye.svg" style="cursor: pointer;" alt=""
                                @click="toggleShowModal(true, row)" />
                            <img src="/img/icons/buttons/edit.svg" style="cursor: pointer;" class="mr-2" alt=""
                                @click="edit(row)" />
                            <img src="/img/icons/buttons/delete.svg" style="cursor: pointer;" alt=""
                                @click="deleteHowToUseApp(row)" />
                        </div>
                    </el-table-column>
                </el-table>
            </el-table-draggable>
        </div>
        <div class="modals">
            <remove-modal :loader="loaders.removeModal" :modal-text="remove.modalText" :removeId="remove.id"
                @onRemove="removeHowToUseApp" @onRemoveMultiple="removeHowToUseAppMultiple" />
            <add-edit-dialog :visible.sync="addEditModal" @closeDialog="closeAddEditModal"
                :how-to-use-app="selectedHowToUseApp" @added="getHowToUseApp" @updated="getHowToUseApp" />
            <show-dialog :visible.sync="showModal" @closeDialog="toggleShowModal(false)" @edit=edit
                :how-to-use-app="selectedHowToUseApp" />
        </div>
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import { Table, TableColumn } from "element-ui";
import RemoveModal from "@/components/Modals/RemoveModal.vue";
import AddEditDialog from "@/views/Components/SuperAdmin/HowToUseApp/AddEditDialog";
import ShowDialog from "@/views/Components/SuperAdmin/HowToUseApp/ShowDialog";
import ElTableDraggable from 'element-ui-el-table-draggable';

export default {
    components: {
        BackButton,
        ElTableDraggable,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        RemoveModal,
        AddEditDialog,
        ShowDialog
    },
    data() {
        return {
            loaders: {
                fetching: false,
                removeModal: false,
                multiple: false,
            },
            search: {
                search: '',
                status: '',
            },
            remove: {
                modalText: '',
                id: ''
            },
            activeOptions: [
                {
                    label: 'All',
                    value: ''
                },
                {
                    label: 'Active',
                    value: 1
                },
                {
                    label: 'Inactive',
                    value: 0
                },
            ],
            addEditModal: false,
            showModal: false,
            howToUseApp: [],
            howToUseAppSelected: [],
            selectedHowToUseApp: null,
        };
    },
    mounted() {
        this.getHowToUseApp();
    },
    methods: {
        edit(item) {
            this.showModal = false;
            this.selectedHowToUseApp = item;
            this.showAddEditModal();
        },
        toggleShowModal(value, item) {
            this.showModal = value;
            if (item) {
                this.selectedHowToUseApp = item;
            } else {
                this.selectedHowToUseApp = null;
            }
        },
        showAddEditModal() {
            this.addEditModal = true;
        },
        closeAddEditModal() {
            this.selectedHowToUseApp = null;
            this.addEditModal = false;
        },
        showMultipleDelete() {
            this.$store.commit("showRemoveModal");
            this.remove.modalText = `You want to remove these selected How To Use App.`;
            this.remove.id = this.howToUseAppSelected;
        },
        deleteHowToUseApp(item) {
            this.$store.commit("showRemoveModal");
            this.remove.modalText = `You want to remove this How To Use App "${item.name}".`;
            this.remove.id = item.id;
        },
        async removeHowToUseApp() {
            try {
                let id = this.remove.id;
                let response = await this.$store.dispatch('HowToUseAppModule/_deleteHowToUseAppById', { id });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'How To Use App',
                    message: message
                });
                this.$store.commit("hideRemoveModal");
                this.getHowToUseApp();

            } catch (error) {
                this.$notify.error({
                    title: 'How To Use App',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });
            }
        },
        async removeHowToUseAppMultiple() {
            this.loaders.removeModal = true;
            this.loaders.multiple = true;
            try {
                let id = this.remove.id;
                let response = await this.$store.dispatch('HowToUseAppModule/_deleteMultipleHowToUseApp', { ids: id });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'How To Use App',
                    message: message
                });
                this.$store.commit("hideRemoveModal");
                this.getHowToUseApp();

            } catch (error) {
                console.log(error);
                this.$notify.error({
                    title: 'How To Use App',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });

            }
            this.loaders.removeModal = false;
            this.loaders.multiple = false;
        },
        async toggleStatusMultiple(status) {
            this.loaders.multiple = true;
            try {
                let id = this.howToUseAppSelected;
                let response = await this.$store.dispatch('HowToUseAppModule/_toggleStatusMultipleHowToUseApp', { fd: { ids: id, status } });
                let {
                    data: { data, message },
                } = response;
                this.$notify.success({
                    title: 'How To Use App',
                    message: message
                });
                this.getHowToUseApp();

            } catch (error) {
                console.log(error);
                this.$notify.error({
                    title: 'How To Use App',
                    message: error?.response?.data?.message || 'Something went wrong! Try again later.'
                });
            }
            this.loaders.multiple = false;
        },
        changePage() {
            this.getHowToUseApp();
        },
        async filterSearch() {
            await this.getHowToUseApp()
        },
        async getHowToUseApp() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('HowToUseAppModule/_getAllHowToUseApp', { params: { ...this.search } });
                let data = response?.data?.data;
                if (data) this.howToUseApp = data;
            } catch (error) {
                this.howToUseApp = [];
            }
            this.loaders.fetching = false;
        },
        handleSelectionChange(val) {
            this.howToUseAppSelected = val.map((el) => el.id);
        },
        async updateSortList(e) {
            let new_index = e.list.findIndex((el) => el.id ==e.targetObject.id);
            let old_index = e.targetObject.order_key;
            let id = e.targetObject.id;
            try {
                let response = await this.$store.dispatch('HowToUseAppModule/_reorderHowToUseApp',  {fd :{ id,new_index,old_index }} );
                let data = response?.data?.data;
              
            } catch (error) {

            }
        }

    },
};
</script>
<style lang="scss">
.how-to-use-app-index {
    .filters {
        .filter-search {
            max-width: 180px;

            .form-control {
                height: calc(1.5em + 1.25rem + 2px);
            }
        }
    }

    .el-table {
        .btnDraggable {
            padding-top: 0px !important;
            padding-left: 0px !important;
            padding-bottom: 0px !important;
            cursor: grabbing;
            margin-left: 0px;
        }

        td {
            background-color: white;

            .status {
                font-size: 12px;
                font-weight: 600;
            }
        }

        .el-checkbox__input {
            &.is-checked {
                .el-checkbox__inner {
                    background-color: #FF6421 !important;
                    border-color: #FF6421 !important;
                }
            }
        }
    }
}
</style>
  