<template>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="handleSubmit(onSubmit)" ref="form" v-loading="loader || loaders.category">
            <div class="how-to-use-app-form mt-1">
                <base-input name="title" label="Title*" v-model="form.title" :rules="{ custom_required: true }"
                    placeholder="Name">
                </base-input>
                <base-select name="category" label="Category" v-model="form.category" :options="categoryOptions"
                    :rules="{ custom_required: false }">
                </base-select>
                <base-select name="status" label="Status*" v-model="form.status" :options="statusOptions"
                    :rules="{ custom_required: true }">
                </base-select>
                <validation-provider :rules="{ custom_required: true, custom_url : true }" name="link"
                    v-slot="{ errors, valid, invalid, validated }">
                    <div class="form-group">
                        <label class="form-control-label">Link*</label>
                        <textarea v-model="form.link" class="form-control" rows="3"
                            :class="[{ 'is-invalid': invalid && validated }]"></textarea>
                        <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
                            {{ errors[0] }}
                        </div>
                    </div>
                </validation-provider>
            </div>
            <div class="text-right mb-2">
                <base-button type="gray" size="xl" class="px-2 mr-3" :disabled="loader" @click="$emit('cancel')">Cancel</base-button>
                <base-button native-type="submit" type="dark-blue" size="xl" :disabled="loader">{{ submitButton }}</base-button>
            </div>
        </form>
    </validation-observer>
</template>
  
<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';

export default {
    props: {
        howToUseApp: Object,
        loader: {
            type: Boolean,
            default: false
        }
    },
    components: {
    },
    mixins: [generateErrorMessageMixin],
    data() {
        return {
            loaders: {
                category : false,
            },
            form: {
                category: '',
                title: '',
                link: '',
                status: 0,
            },
            statusOptions: [
                {
                    label: 'Active',
                    value: 1,
                },
                {
                    label: 'Inactive',
                    value: 0,
                }
            ],
            categoryOptions: []
        };
    },
    mounted() {
        this.getCategories()
        if (this.howToUseApp) {
            this.setValues(this.howToUseApp);
        }
    },
    watch: {
        'howToUseApp': function (newValue) {
            this.setValues(newValue);
        }
    },
    methods: {
        setValues(value) {
            this.form.category = value?.use_link_category_id || '';
            this.form.title = value?.name || '';
            this.form.link = value?.content || '';
            this.form.status = value?.status || 0;
        },
        async onSubmit() {
            if(this.howToUseApp) this.$emit('update', {fd:this.form,id:this.howToUseApp.id});
            else this.$emit('add', this.form);
        },
        async getCategories() {
            this.loaders.category = true;
            try {
                let response = await this.$store.dispatch('HowToUseAppModule/_getHowToUseAppCategorySelect', { params: {} });
                let data = response?.data?.data;
                this.categoryOptions = data.map((el)=>{
                    return {
                        label : el.name,
                        value : el.id
                    }
                });
                this.categoryOptions = [{label:'Select Category',value: ''},...this.categoryOptions]
            } catch (error) {
                
            }
            this.loaders.category = false;
        },
    },
    computed:{
        submitButton(){
            return !this.howToUseApp ? 'Save' : 'Update';
        }
    }
};
</script>
  
<style lang="scss">
.how-to-use-app-form {
    .form-group{
        margin-bottom: 0.65rem !important;
    }
    input {
        &.form-control {
            height: calc(1.5em + 1.25rem + 2px);
        }
    }
}
</style>
  